<template>
    <div class="who-uses-zubut">
        <b-row>
            <b-col>
                <section class="my-md-5">
                    <h2 class="z-text-blue z-text-small text-md-left mb-3">
                        ¿Quiénes usan Zubut?
                    </h2>
                    <p class="z-text-bigger text-md-left mb-md-5">
                        Conoce la opinión de nuestros clientes
                    </p>
                    <b-row class="client-logos">
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-liverpool.png" alt="liverpool" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-marisa.png" alt="marisa" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-pechugon.png" alt="pechugon" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-omigoto.png" alt="omigoto" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img
                                src="@/assets/img/logo-farmaciagdl.png"
                                alt="farmacias guadalajara"
                            />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-alnut.png" alt="alnut" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-okuma.png" alt="okuma sushi" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-manhattan.png" alt="manhallan" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-cotidiano.png" alt="cotidiano" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-yza.png" alt="yza farmacias" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-shopandares.png" alt="shop andares" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-birdman.png" alt="birdman" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-rincondelmar.png" alt="rincón del mar" />
                        </b-col>
                        <b-col md="3" lg="2">
                            <img src="@/assets/img/logo-shopet.png" alt="shopet" />
                        </b-col>
                    </b-row>
                </section>
            </b-col>
        </b-row>
        <b-row class="mb-5">
            <b-col
                v-for="(testimonial, index) in testimonials"
                :key="index"
                xs="12"
                md="4"
                class="client-opinion-container"
            >
                <ClientTestimonialItem :testimonial="testimonial" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ClientTestimonialItem from "@/components/ClientTestimonialItem";

export default {
    name: "ClientTestimonials",
    components: {
        ClientTestimonialItem
    },
    data() {
        return {
            testimonials: [
                {
                    quote:
                        "Nuestros clientes están muy contentos con la nueva experiencia y velocidad de entrega",
                    name: "Samuel",
                    role: "Gerente, Liverpool Andares",
                    class: "liverpool",
                    image: "img/avatar-liverpool.png"
                },
                {
                    quote:
                        "Nunca me preocupo por la rotación que antes tenía, con Zubut tengo garantía de entrega",
                    name: "Ana",
                    role: "Encargada de tienda, Farmacias YZA",
                    class: "farmyza",
                    image: "img/avatar-farmacias-yza.png"
                },
                {
                    quote: "Sin ninguna inversión seguimos creciendo nuestras entregas a domicilio",
                    name: "Isabel",
                    role: "Encargada de tienda, Pechugón",
                    class: "pechugon",
                    image: "img/avatar-pechugon.png"
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.who-uses-zubut {
    padding: 3em 0em;
}

.client-logos {
    img {
        width: 120px;
        margin-bottom: 2em;
    }
}

.client-opinion-container {
    margin-top: 2em;
    display: flex;
    justify-content: center;
}
</style>
