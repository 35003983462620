<template>
    <b-row class="home-business-type-menu">
        <b-col class="no-gutters">
            <div>
                <h2 class="z-text-blue z-text-small text-md-left mb-3">
                    Beneficios
                </h2>
                <p class="z-text-bigger text-md-left">
                    Consulta los beneficios según tu tipo de empresa
                </p>
                <b-row>
                    <b-col md="3" class="no-gutters">
                        <b-nav class="business-types-menu d-sm-flex d-inline">
                            <b-nav-item
                                v-for="tab in tabs"
                                :key="`tabs-${tab.value}`"
                                :active="tab.active"
                                @click="setActiveTab(tab)"
                                class="text-md-left"
                            >
                                {{ tab.text }}
                            </b-nav-item>
                        </b-nav>
                        <div class="text-md-left btn-zubut-business-container d-none d-md-block">
                            <z-button
                                class="btn-zubut-business"
                                variant="secondary"
                                @click="$router.push({ name: 'registrar-empresa' })"
                            >
                                Zubut para empresas
                            </z-button>
                        </div>
                    </b-col>
                    <b-col md="1"></b-col>
                    <b-col md="8">
                        <div class="tab-content">
                            <b-row v-show="selectedTab.value === 0">
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-multiviajes.png" alt="" />
                                    <p><b>Viajes multipunto</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-grafica.png" alt="" />
                                    <p><b>Estadísticas</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-factura.png" alt="" />
                                    <p><b>Facturación electrónica</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-factura-02.png" alt="" />
                                    <p><b>Facturación consolidada</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-plataforma.png" alt="" />
                                    <p><b>Panel de administradores para gerentes</b></p>
                                </b-col>
                            </b-row>
                            <b-row align-h="start" v-show="selectedTab.value === 1">
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-multiviajes.png" alt="" />
                                    <p><b>Viajes multipunto</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-dinero.png" alt="" />
                                    <p><b>Cobrar y depositar</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-firmas.png" alt="" />
                                    <p><b>Firma de recibido</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-grafica.png" alt="" />
                                    <p><b>Estadísticas</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-programar.png" alt="" />
                                    <p><b>Agendar servicio</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-factura.png" alt="" />
                                    <p><b>Facturación electrónica</b></p>
                                </b-col>
                                <b-col cols="6" md="3">
                                    <img src="@/assets/img/ic-factura-02.png" alt="" />
                                    <p><b>Facturación consolidada</b></p>
                                </b-col>
                            </b-row>
                            <b-row v-show="selectedTab.value === 2">
                                <b-col>
                                    <img src="@/assets/img/ic-integracion.png" alt="" />
                                    <p><b>Integración</b></p>
                                </b-col>
                                <b-col>
                                    <img src="@/assets/img/ic-custom.png" alt="" />
                                    <p><b>Personalización</b></p>
                                </b-col>
                                <b-col>
                                    <img src="@/assets/img/ic-soporte.png" alt="" />
                                    <p><b>Ayuda y soporte</b></p>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="3" class="text-md-left btn-zubut-business-container d-sm-none">
                        <z-button class="btn-zubut-business" variant="secondary">
                            Zubut para empresas
                        </z-button>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import ZButton from "@/components/ZButton";

export default {
    name: "BusinessTypeMenu",
    components: {
        ZButton
    },
    data() {
        return {
            tabs: [
                { value: 0, text: "Restaurantes", active: true },
                { value: 1, text: "Negocios", active: false },
                { value: 2, text: "eCommerce", active: false }
            ],
            selectedTab: { value: 0, text: "Restaurantes", active: true }
        };
    },
    methods: {
        setActiveTab(tab) {
            this.tabs = this.tabs.map(item => {
                item.active = item.value === tab.value;
                return item;
            });
            this.selectedTab = { ...tab };
        }
    }
};
</script>

<style lang="scss" scoped>
$zubut-blue: #0077fe;

.home-business-type-menu {
    padding-top: 1em;
}

.business-types-menu {
    position: relative;
    justify-content: space-around;
    font-size: 11px;

    &::before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 4px;
        background: #dcdfe6;
    }

    .nav-item {
        .nav-link {
            position: relative;
            font-weight: 500;
            color: #8190a1;

            &.active {
                color: $zubut-blue;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                    height: 4px;
                    background: $zubut-blue;
                }
            }

            &::before {
                content: "";
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                height: 4px;
                background: #dcdfe6;
            }
        }
    }
}

.tab-content {
    padding: 1em;

    img {
        height: 75px;
        width: 75px;
    }
}

.btn-zubut-business-container {
    padding: 3em;
}

@media (min-width: 576px) {
    .home-business-type-menu {
        height: 665px;
    }
}

@media only screen and (min-width: 768px) {
    .home-business-type-menu {
        height: 372px;
    }

    .business-types-menu {
        padding-left: 1em;
        flex-direction: column !important;

        &::before {
            display: none;
        }

        .nav-item {
            font-size: 18px;

            .nav-link {
                &.active {
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0px;
                        left: 4px;
                        width: 4px;
                        height: 100%;
                        background: $zubut-blue;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    left: 4px;
                    width: 4px;
                    height: 100%;
                    background: #dcdfe6;
                }
            }
        }

        .tab-content {
            .row {
                .col {
                    text-align: left !important;
                }
            }
        }
    }

    .btn-zubut-business-container {
        margin-top: 4em;
        padding: 1em;
    }
}

@media only screen and (min-width: 992px) {
    .home-business-type-menu {
        height: 370px;
    }
}

@media only screen and (min-width: 1200px) {
    .home-business-type-menu {
        height: 372px;
    }
}
</style>
