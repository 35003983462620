<template>
    <b-form @submit.prevent="handleSubmit">
        <b-row>
            <b-col>
                <b-alert
                    :show="timeToshowErrorAlert"
                    variant="danger"
                    class="z-text-smaller"
                    dismissible
                    @dismissed="timeToshowErrorAlert = 0"
                    @dismiss-count-down="countDownErrorAlertChanged"
                >
                    {{ errorMessage }}
                </b-alert>
                <b-alert
                    :show="timeToShowSuccessAlert"
                    variant="success"
                    class="z-text-smaller"
                    dismissible
                    @dismissed="timeToShowSuccessAlert = 0"
                    @dismiss-count-down="countDownSuccessAlertChanged"
                >
                    Cuenta creada con éxito, revisa tu correo electrónico y tu bandeja de correos no
                    deseados para activar tu cuenta.
                </b-alert>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="pr-1">
                <z-input
                    class="form-input"
                    v-model="name"
                    label="Nombre(s)"
                    type="text"
                    :state="isInputNameValid"
                    id="input-name"
                />
            </b-col>
            <b-col class="pl-1">
                <z-input
                    class="form-input"
                    v-model="lastname"
                    label="Apellido(s)"
                    type="text"
                    :state="isInputLastNameValid"
                    id="input-last-name"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-select
                    v-model="businessLine"
                    inputDescription="Tipo de empresa"
                    :options="businessLineOptions"
                    :isSelectInputValid="isSelectInputBusinessLineValid"
                    class="form-input"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-input
                    v-if="isECommerce"
                    id="input-website-group"
                    class="form-input"
                    v-model="website"
                    label="Sitio web (Opcional)"
                    type="text"
                    :state="isInputWebsiteValid"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-input
                    class="form-input"
                    v-model="phone"
                    label="Teléfono o Celular"
                    type="tel"
                    :state="isInputPhoneValid"
                    id="name-phone"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-input
                    class="form-input"
                    v-model="email"
                    label="Correo electrónico"
                    :state="isInputEmailValid"
                    name="email"
                    type="email"
                    id="input-email"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-input
                    class="form-input"
                    v-model="password"
                    label="Contraseña"
                    :state="isInputPasswordValid"
                    name="password"
                    type="password"
                    id="input-password"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-input
                    class="form-input"
                    v-model="passwordConfirm"
                    label="Confirma tu contraseña"
                    :state="isInputPasswordConfirmValid"
                    name="password"
                    type="password"
                    id="input-password"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-select
                    v-model="avgDeliveries"
                    inputDescription="Numero de pedidos prom. al mes"
                    :options="avgDeliveriesOptions"
                    :isSelectInputValid="isSelectInputAvgDeliveriesValid"
                    class="form-input"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-select
                    v-model="salesChannel"
                    inputDescription="Canal de Venta"
                    :options="salesChannelOptions"
                    :isSelectInputValid="isSelectInputSalesChannelValid"
                    class="form-input"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-select
                    v-model="conversionChannel"
                    inputDescription="¿Dónde escuchaste de nosotros?"
                    :options="conversionChannelOptions"
                    :isSelectInputValid="isSelectInputConversionChannelValid"
                    class="form-input"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <z-button
                    :class="{ error: error }"
                    class="form-input"
                    type="submit"
                    size="lg"
                    uppercase
                    bold
                    :disabled="isRequesting"
                >
                    <div v-if="isRequesting" class="mt-2">
                        <z-loading></z-loading>
                    </div>
                    <span v-else-if="error">Revisa tu información</span>
                    <span v-else>Registrarme</span>
                </z-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p class="z-text-smaller text-left mt-4">
                    Al continuar, aceptas nuestro
                    <router-link :to="{ name: 'aviso-de-privacidad' }">
                        Aviso de privacidad
                    </router-link>
                    y nuestros
                    <router-link :to="{ name: 'terminos-y-condiciones' }">
                        Términos y Condiciones
                    </router-link>
                    de uso.
                </p>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import ZInput from "./ZInput.vue";
import ZSelect from "./ZSelect.vue";
import Type from "@/constants/type";
import BusinessLine from "@/constants/businessLine";
import Clients from "@/services/clients.js";
import trackActionWithAnalytics from "@/utils/track-Action-With-Analytics.js";
import Validators from "@/utils/validators";
import FormatNumbers from "@/utils/formatNumbers";
import ConversionChannel from "@/constants/conversionChannel";
import SalesChannel from "@/constants/salesChannel";
import { BAlert } from "bootstrap-vue";

export default {
    name: "BusinessSignUp",
    components: {
        ZInput,
        ZSelect,
        BAlert
    },
    watch: {
        businessLine: {
            handler(newVal, oldVal) {
                if (oldVal === BusinessLine.NUM_ECOMMERCE) {
                    this.website = "";
                }
            }
        },
        error: {
            handler() {
                if (this.error) {
                    setTimeout(() => {
                        this.error = false;
                    }, 5000);
                }
            }
        }
    },
    data() {
        return {
            error: false,
            businessLine: null,
            businessLineOptions: BusinessLine.options,
            email: "",
            name: "",
            password: "",
            lastname: "",
            passwordConfirm: "",
            phone: "",
            website: "",
            avgDeliveries: null,
            avgDeliveriesOptions: ["50 a 100", "100 a 200", "200 a 300", "300 a 400", "400 a más"],
            salesChannel: null,
            salesChannelOptions: SalesChannel.options,
            conversionChannel: null,
            conversionChannelOptions: ConversionChannel.options,
            isRequesting: false,
            timeToDismissInSecs: 5,
            timeToshowErrorAlert: 0,
            timeToShowSuccessAlert: 0,
            errorMessage: "Ocurrió un error al intentar registrarse. Vuelvelo a intentar más tarde."
        };
    },

    computed: {
        isInputNameValid() {
            return Validators.isNotEmpty(this.name, this.error);
        },
        isInputLastNameValid() {
            return Validators.isNotEmpty(this.lastname, this.error);
        },
        isInputPhoneValid() {
            return Validators.isValidPhone(this.phone, this.error);
        },
        isSelectInputBusinessLineValid() {
            return Validators.isDropdownSelected(this.businessLine, this.error);
        },
        isInputWebsiteValid() {
            return Validators.isValidWebsite(this.website, this.error);
        },
        isInputEmailValid() {
            return Validators.isEmail(this.email, this.error);
        },
        isInputPasswordValid() {
            return Validators.isValidPasswordLength(this.password, this.error);
        },
        isInputPasswordConfirmValid() {
            return (
                Validators.isValidPasswordLength(this.passwordConfirm, this.error) &&
                Validators.isPasswordConfirmEqualToPassword(
                    this.password,
                    this.passwordConfirm,
                    this.error
                )
            );
        },
        isSelectInputAvgDeliveriesValid() {
            return Validators.isDropdownSelected(this.avgDeliveries, this.error);
        },
        isSelectInputSalesChannelValid() {
            return Validators.isDropdownSelected(this.salesChannel, this.error);
        },
        isSelectInputConversionChannelValid() {
            return Validators.isDropdownSelected(this.conversionChannel, this.error);
        },
        isECommerce() {
            return this.businessLine === BusinessLine.NUM_ECOMMERCE;
        }
    },

    methods: {
        countDownErrorAlertChanged(time) {
            this.timeToshowErrorAlert = time;
        },
        countDownSuccessAlertChanged(time) {
            this.timeToShowSuccessAlert = time;
        },
        validForm() {
            if (
                this.isInputNameValid &&
                this.isInputLastNameValid &&
                this.isInputPhoneValid &&
                this.isSelectInputBusinessLineValid &&
                this.isInputWebsiteValid &&
                this.isInputEmailValid &&
                this.isInputPasswordValid &&
                this.isInputPasswordConfirmValid &&
                this.isSelectInputAvgDeliveriesValid &&
                this.isSelectInputSalesChannelValid &&
                this.isSelectInputConversionChannelValid
            )
                return true;
            this.error = true;
            return false;
        },
        async handleSubmit() {
            if (!this.validForm()) return;

            this.isRequesting = true;
            const {
                email,
                name,
                lastname,
                password,
                phone,
                businessLine,
                conversionChannel,
                website,
                avgDeliveries,
                salesChannel
            } = this;
            const fullname = `${name} ${lastname}`;
            const data = {
                email,
                name: fullname,
                password,
                phone: FormatNumbers.convertIntoNumber(phone),
                type: Type.get["Cliente"],
                contact: fullname,
                businessLine,
                conversionChannel,
                website,
                avgDeliveries,
                salesChannel
            };
            Clients.register(data)
                .then(res => {
                    if (res.status && res.status === 401) {
                        throw new Error(res);
                    }
                    this.timeToShowSuccessAlert = this.timeToDismissInSecs;
                    this.cleanForm();
                    trackActionWithAnalytics.facebook("Lead", "registrar empresa");
                    trackActionWithAnalytics.google("formulario", "registrar empresa");
                })
                .catch(error => {
                    this.timeToshowErrorAlert = this.timeToDismissInSecs;
                    if (error.statusCode === 422) {
                        this.errorMessage = "Ya existe un usuario con estos datos.";
                    }
                    this.$captureError(error);
                })
                .finally(() => {
                    this.isRequesting = false;
                });
        },

        cleanForm() {
            this.email = "";
            this.name = "";
            this.lastname = "";
            this.password = "";
            this.passwordConfirm = "";
            this.phone = "";
            this.businessLine = null;
            this.conversionChannel = null;
            this.website = "";
            this.avgDeliveries = null;
            this.salesChannel = null;
        }
    }
};
</script>
<style lang="scss" scoped>
.error {
    background: $medium-carmine;
}
.form-input {
    margin-top: 15px;
}
</style>
