<template>
    <div style="background: #F9FAFC" class="quote-service negative-margin-sides">
        <b-container>
            <b-row>
                <b-col>
                    <section class="quote-service-container">
                        <b-row>
                            <b-col sm="12" md="4">
                                <h2 class="z-text-blue z-text-small text-md-left mb-3">
                                    Cotiza aquí
                                </h2>
                                <p class="z-text-bigger text-md-left">
                                    Estima el costo de tu envío
                                </p>
                                <img
                                    class="request-panel-image quote-service-image"
                                    src="@/assets/img/request-panel.png"
                                    alt="quote cost image"
                                />
                                <p class="z-text-light text-md-left available-regions">
                                    Disponibles en Zona Metropolitana de Guadalajara, CDMX, León,
                                    Querétaro y Monterrey
                                </p>
                            </b-col>
                            <b-col sm="12" md="8">
                                <z-picture
                                    img="home-map.jpeg"
                                    alt="quote cost image"
                                    className="w-100"
                                />
                            </b-col>
                        </b-row>
                    </section>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import ZPicture from "./ZPicture.vue";
export default {
    components: { ZPicture },
    name: "EstimateCost"
};
</script>

<style lang="scss" scoped>
.quote-service {
    padding: 1em;

    .quote-service-container {
        display: flex;

        .request-panel-image {
            margin-bottom: 1em;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        }

        .available-regions {
            align-self: flex-end;
        }
    }
}

@media only screen and (min-width: 768px) {
    .quote-service-container {
        text-align: left;

        .request-panel-image {
            width: 130%;
            max-width: 130% !important;
            z-index: 1;
        }
    }
}
</style>
