<template>
    <b-container class="home-zone-coverage px-0">
        <b-row>
            <b-col>
                <section>
                    <h2 class="z-text-blue z-text-small text-md-left mb-3">
                        Disponible en
                    </h2>
                    <b-row>
                        <b-col
                            md="6"
                            class="no-gutters main-zone-container city"
                            :class="{ active: selectedZone === 'zmgdl' }"
                        >
                            <b-nav class="text-center">
                                <b-nav-item
                                    class="text-md-left z-text-bold main-zone w-100 pb-xl-2"
                                    @click="selectedZone = 'zmgdl'"
                                >
                                    Zona Metropolitana de Guadalajara
                                </b-nav-item>
                            </b-nav>
                        </b-col>
                        <b-col md="6" class="no-gutters">
                            <b-nav class="zones-menu">
                                <b-nav-item
                                    class="text-md-left city z-text-bold"
                                    :class="{ active: selectedZone === 'leongto' }"
                                    @click="selectedZone = 'leongto'"
                                >
                                    León, Gto.
                                </b-nav-item>
                                <b-nav-item
                                    class="text-md-left city ml-xl-n2 z-text-bold"
                                    :class="{ active: selectedZone === 'mtynl' }"
                                    @click="selectedZone = 'mtynl'"
                                >
                                    Monterrey, Nl.
                                </b-nav-item>
                                <b-nav-item
                                    class="text-md-left city ml-xl-n1 z-text-bold"
                                    :class="{ active: selectedZone === 'qro' }"
                                    @click="selectedZone = 'qro'"
                                >
                                    Querétaro, Qro.
                                </b-nav-item>
                            </b-nav>
                        </b-col>
                    </b-row>
                    <div class="menu-stripe"></div>
                    <section v-show="selectedZone === 'zmgdl'" class="zmgdl-zone">
                        <b-row class="zone-converage-container">
                            <b-col sm="12" md="5" class="zone-coverage">
                                <img
                                    class="coverage-period"
                                    src="@/assets/img/ic-sun.png"
                                    alt="cobertura diurna"
                                />
                                <p class="coverage-type">
                                    Cobertura diurna
                                </p>
                                <p class="coverage-time">
                                    9:00 a.m. - 6:30 p.m.
                                </p>
                                <p class="coverage-days">
                                    Lunes a sábado: 9:00 - 22:00<br />
                                    Domingo: 10:00 - 21:00
                                </p>
                                <div class="zone-image-container">
                                    <z-picture
                                        img="day-map"
                                        alt="cobertura diurna guadalajara"
                                        className="zone-coverage"
                                    />
                                </div>
                                <!-- <b-row>
                                    <b-col class="button-container" md="8" offset-md="2">
                                        <z-button
                                            variant="secondary"
                                            @click="$router.push({ name: 'cotizar-envio' })"
                                        >
                                            Verifica la cobertura de tu domicilio
                                        </z-button>
                                    </b-col>
                                </b-row> -->
                            </b-col>
                            <b-col class="vertical-line d-none d-md-block" md="1" offset-md="1">
                                <div style="border-left:1px solid #D8D8D8;height:700px"></div>
                            </b-col>
                            <b-col sm="12" md="5" class="zone-coverage">
                                <img
                                    class="coverage-period"
                                    src="@/assets/img/ic-night.png"
                                    alt="cobertura diurna"
                                />
                                <p class="coverage-type">
                                    <span>Cobertura nocturna</span>
                                    <img
                                        class="help-icon"
                                        src="@/assets/icons/help.svg"
                                        alt="ayuda"
                                    />
                                </p>
                                <p class="coverage-time">
                                    6:00 p.m. - 10:00 p.m.
                                </p>
                                <p class="coverage-days">
                                    Lunes a sábado: 9:00 - 22:00<br />
                                    Domingo: 10:00 - 21:00
                                </p>
                                <div class="zone-image-container">
                                    <z-picture
                                        img="night-map"
                                        alt="cobertura nocturna guadalajara"
                                        className="zone-coverage"
                                    />
                                </div>
                                <!-- <b-row>
                                    <b-col class="button-container" md="8" offset-md="2">
                                        <z-button
                                            variant="secondary"
                                            @click="$router.push({ name: 'cotizar-envio' })"
                                        >
                                            Verifica la cobertura de tu domicilio
                                        </z-button>
                                    </b-col>
                                </b-row> -->
                            </b-col>
                        </b-row>
                    </section>
                    <section v-show="selectedZone !== 'zmgdl'" class="leongto-zone">
                        <b-row class="zone-converage-container">
                            <b-col sm="12" md="6" class="zone-coverage">
                                <div class="zone-image-container">
                                    <z-picture
                                        v-show="selectedZone === 'leongto'"
                                        img="day-map-leon"
                                        alt="cobertura zubut en de león guanajuato"
                                        className="zone-coverage"
                                    />
                                    <z-picture
                                        v-show="selectedZone === 'mtynl'"
                                        img="day-map-mty"
                                        alt="cobertura zubut en de monterrey nuevo león"
                                        className="zone-coverage"
                                    />
                                    <z-picture
                                        v-show="selectedZone === 'qro'"
                                        img="day-map-queretaro"
                                        alt="cobertura zubut en querétaro"
                                        className="zone-coverage"
                                    />
                                </div>
                            </b-col>
                            <b-col class="text-left">
                                <p class="coverage-type font-weight-bold z-text-big">
                                    Contáctanos para conocer más sobre horarios y zona de cobertura
                                </p>
                                <div class="font-weight-bold">Atención a clientes</div>
                                <div class="coverage-days">
                                    Lunes a Viernes de 9:00 am a 6:00 pm
                                </div>
                                <div>
                                    Teléfono: (33) 3503-8685
                                </div>
                            </b-col>
                        </b-row>
                    </section>
                </section>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import ZPicture from "./ZPicture.vue";
export default {
    components: { ZPicture },
    name: "ZoneCoverage",

    data() {
        return {
            selectedZone: "zmgdl"
        };
    }
};
</script>

<style lang="scss" scoped>
$gray: #94a1b0;
$zubut-blue: #0077fe;

.home-zone-coverage {
    padding: 4em 0em;
}

.zone-converage-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-container {
        padding: 0em 2em;
    }
}

.zones-menu {
    .city {
        width: 100%;
        a {
            color: $gray !important;
            font-size: 16px;
            line-height: 32px;
        }

        &.active a {
            color: $zubut-blue !important;
        }
    }
}

.zone-coverage {
    padding: 1em;

    .coverage-period {
        width: 65px;
    }

    .coverage-type {
        position: relative;
        font-size: 24px;

        .help-icon {
            position: absolute;
            margin-top: 10px;
            margin-left: 10px;
        }
    }

    .coverage-time {
        font-size: 15px;
        color: #8190a1;
        font-weight: 600;
    }

    .coverage-days {
        font-size: 14px;
    }

    .zone-image-container {
        padding: 1em;
    }

    button {
        margin-top: 2em;
    }
}

.main-zone-container {
    a {
        color: $gray;
    }

    &.active a {
        color: $zubut-blue;
    }
}

@media only screen and (min-width: 768px) {
    .zones-menu {
        .city {
            width: auto;
        }
    }

    .menu-stripe {
        height: 4px;
        width: 100%;
        background-color: #dcdfe6;
        margin-top: -4px;
        margin-bottom: 3em;
    }

    .zone-converage-container {
        .button-container {
            padding: 0em;
        }
    }
}

@media only screen and (min-width: 992px) {
    .main-zone-container {
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            bottom: 0px;
            left: 1rem;
        }

        &.active {
            &::before {
                content: "";
                background-color: $zubut-blue;
            }
        }
    }

    .zones-menu .city {
        &.active a {
            position: relative;
            color: $zubut-blue !important;

            &::before {
                content: "";
                position: absolute;
                width: calc(100% - 32px);
                height: 4px;
                bottom: 0px;
                left: 1rem;
                background-color: $zubut-blue;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .main-zone-container {
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            bottom: 0px;
            left: 1rem;
        }

        &.active::before {
            background-color: $zubut-blue;
        }
    }
}
</style>
