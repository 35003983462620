const ECOMMERCE = "eCommerce";
const SOCIAL_NETWORK = "Red Social";

const NUM_ECOMMERCE = 0;
const NUM_SOCIAL_NETWORK = 1;

const get = {};
get[NUM_ECOMMERCE] = ECOMMERCE;
get[NUM_SOCIAL_NETWORK] = SOCIAL_NETWORK;

const options = [
    { value: NUM_ECOMMERCE, text: ECOMMERCE },
    { value: NUM_SOCIAL_NETWORK, text: SOCIAL_NETWORK }
];

export default {
    get,
    options,
    ECOMMERCE,
    SOCIAL_NETWORK,
    NUM_ECOMMERCE,
    NUM_SOCIAL_NETWORK
};
