<template>
    <b-container>
        <b-row class="press-logos d-flex justify-content-between mt-3">
            <b-col v-show="showPin" sm="12" md="1">
                <img class="destination" src="@/assets/img/ic-destination.png" alt="destination" />
            </b-col>
            <b-col sm="12" md="4">
                <img
                    class="mr-md-auto"
                    style="height: 24px;"
                    src="@/assets/img/logo-prensa-01.png"
                    alt="angellist"
                />
            </b-col>
            <b-col sm="12" md="4">
                <img
                    style="height: 18px;"
                    src="@/assets/img/logo-prensa-03.png"
                    alt="informador.mx"
                />
            </b-col>
            <b-col sm="12" md="3" class="text-md-right">
                <img
                    class="ml-md-auto"
                    style="height: 40px;"
                    src="@/assets/img/logo-prensa-02.png"
                    alt="zona tres 91.5 fm"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "PressBar",
    props: {
        showPin: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.press-logos {
    div {
        display: flex;
        justify-content: center;

        img {
            align-self: flex-end;
            margin-top: 2em;
        }

        .destination {
            max-width: 24px;
        }
    }
}
</style>
