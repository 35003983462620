<template>
    <div class="client-testimonial text-left" :class="[testimonial.class]">
        <img
            class="client-logo"
            :src="require(`@/assets/${testimonial.image}`)"
            :alt="testimonial.name"
            width="70"
            height="70"
        />
        <div class="d-flex flex-column justify-content-between testimonial-wrapper">
            <div>
                <p class="opinion-text z-text-bigger">“{{ testimonial.quote }}”</p>
            </div>
            <div>
                <hr />
                <p class="client-name">{{ testimonial.name }}</p>
                <p class="client-rol z-text-light z-text-bold z-text-small">
                    {{ testimonial.role }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ClientTestimonialItem",
    props: {
        testimonial: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.client-testimonial {
    width: 100%;
    max-width: 324px;
    padding: 1em;
    border-radius: 6px;
    border: 1px solid #eef4ff;
    margin-bottom: 3em;
    transition: all 0.3s;

    .opinion-text {
        margin-top: 1.8rem;
        margin-bottom: 0rem;
    }

    .client-logo {
        width: 70px;
        margin-top: -33px;
        border-radius: 2px;
    }

    .client-name {
        margin: 0px;
    }

    &.liverpool {
        box-shadow: inset 0 -5px 0 0 #d556ac, 0 13px 12px 2px rgba(158, 198, 241, 0.25);

        &:hover {
            box-shadow: inset 0 -5px 0 0 #d556ac, 0 13px 12px 8px rgba(158, 198, 241, 0.25);
        }
    }

    &.pechugon {
        box-shadow: inset 0 -5px 0 0 #d33c3d, 0 13px 12px 2px rgba(158, 198, 241, 0.25);

        &:hover {
            box-shadow: inset 0 -5px 0 0 #d33c3d, 0 13px 12px 8px rgba(158, 198, 241, 0.25);
        }
    }

    &.farmyza {
        box-shadow: inset 0 -5px 0 0 #002593, 0 13px 12px 2px rgba(158, 198, 241, 0.25);

        &:hover {
            box-shadow: inset 0 -5px 0 0 #002593, 0 13px 12px 8px rgba(158, 198, 241, 0.25);
        }
    }
}

.testimonial-wrapper {
    height: calc(100% - 26px);
}
</style>
