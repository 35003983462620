const CLIENT = "Cliente";
const COMPANY = "Compañia";
const BRANCH = "Sucursal";

const NUM_CLIENT = 0;
const NUM_COMPANY = 1;
const NUM_BRANCH = 2;

const get = {};
get[NUM_CLIENT] = CLIENT;
get[NUM_COMPANY] = COMPANY;
get[NUM_BRANCH] = BRANCH;
get[CLIENT] = NUM_CLIENT;
get[COMPANY] = NUM_COMPANY;
get[BRANCH] = NUM_BRANCH;

const options = [
    { value: NUM_CLIENT, text: CLIENT },
    { value: NUM_COMPANY, text: COMPANY },
    { value: NUM_BRANCH, text: BRANCH }
];

export default {
    get,
    options,
    NUM_CLIENT,
    NUM_COMPANY,
    NUM_BRANCH
};
